<template>
  <div id="proDetail">
    <header-bar title="项目详情" />
    <div class="topWrap">
      <h3 class="f32 oneClamp">{{ info.name }}</h3>
      <span>{{ info.project_status }}</span>
    </div>
    <section>
      <div class="title f32">
        项目信息
      </div>
      <ul class="infoList">
        <li>
          <label>项目名称</label>
          <h5>{{ info.name }}</h5>
        </li>
        <li>
          <label>项目属性</label>
          <h5>{{ info.project_attribute }}</h5>
        </li>
        <li>
          <label>项目类型</label>
          <h5>{{ info.project_type }}</h5>
        </li>
        <li>
          <label>项目负责人</label>
          <h5>{{ info.manager }}</h5>
        </li>
        <li>
          <label>联系电话</label>
          <h5>{{ info.phone }}</h5>
        </li>
        <li>
          <label>项目预计金额</label>
          <h5>{{ info.budget_amount }}元</h5>
        </li>
        <li>
          <label>项目实施时间</label>
          <h5>{{ info.implement_time }}</h5>
        </li>
        <li>
          <label>预计效果</label>
          <h5>{{ info.effect }}</h5>
        </li>
        <li>
          <label>需求描述</label>
          <h5>{{ info.description }}</h5>
        </li>
        <li>
          <label>实施地点</label>
          <h5>{{ info.execute_address }}</h5>
        </li>
        <li>
          <label>参与单位</label>
          <div>
            <h5 v-for="(item, index) in info.participant" :key="index" class="oneClamp">{{ item.branch_name }}</h5>
          </div>

        </li>

      </ul>
    </section>
    <section>
      <div class="title f32">
        供应商信息
      </div>
      <ul class="infoList">
        <li>
          <label>供应商名称</label>
          <h5>{{ info.outbid_company_name }}</h5>
        </li>
        <li>
          <label>联系人</label>
          <h5>{{ info.outbid_contacts }}</h5>
        </li>
        <li>
          <label>联系电话</label>
          <h5>{{ info.outbid_phone }}</h5>
        </li>
      </ul>
    </section>

    <section v-for="(item, index) in info.check_list" :key="index">
      <div class="title f32">
        飞行检查
      </div>
      <ul class="infoList">
        <li>
          <label>检查人员</label>
          <h5>{{ item.truename }}</h5>
        </li>
        <li>
          <label>所属单位</label>
          <h5>{{ item.branch_name }}</h5>
        </li>
        <li>
          <label>检查地点</label>
          <h5>{{ item.branch_name }}</h5>
        </li>
        <li>
          <label>检查时间</label>
          <h5>{{ item.created_time }}</h5>
        </li>
      </ul>
      <ul class="fileList">
        <li
          v-for="(file, fileIndex) in item.check_files"
          :key="fileIndex"
          @click="previewImage(item.check_files, fileIndex)"
        >
          <van-image
            class="file"
            fit="cover"
            :src="file.file_url"
          />
        </li>
      </ul>
      <ul class="infoList">
        <li>
          <label>检查评分</label>
          <ul class="rateList">
            <li v-for="rate in rateList" :key="rate.key">
              <span>{{ rate.label }}</span>
              <div class="stars">
                <i
                  v-for="star in 5"
                  :key="star"
                  :class="{'active': item[rate.key] >= star}"
                />
              </div>
            </li>
          </ul>
        </li>
        <li>
          <label>检查描述</label>
          <h5>{{ item.description }}</h5>
        </li>
      </ul>
    </section>
    <section>
      <div class="title f32">
        其他信息
      </div>
      <ul class="infoList">
        <li>
          <label>实施编号</label>
          <h5>{{ info.sn }}</h5>
        </li>
        <li>
          <label>创建人</label>
          <h5>{{ info.created_by_truename }}</h5>
        </li>
        <li>
          <label>创建单位</label>
          <h5>{{ info.created_by_company }}</h5>
        </li>
        <li>
          <label>创建时间</label>
          <h5>{{ info.created_time }}</h5>
        </li>
      </ul>
    </section>

    <FooterBar v-if="info.project_status === '实施中'" confirm-text="飞行检查" @clickConfirm="clickConfirm" />

  </div>
</template>

<script>
// import { } from 'vuex'
import HeaderBar from '../components/HeaderBar/index';
import FooterBar from '../components/FooterBar/index';
export default {
  name: 'ProDetail',
  components: { FooterBar, HeaderBar },
  props: [],
  data() {
    return {
      info: {},
      rateList: [
        { label: '现场效果', key: 'effect' },
        { label: 'KPI达成', key: 'kpi_reach' },
        { label: '人员状态', key: 'staff_status' },
        { label: '方案符合度', key: 'plan_accord' }
      ]
    };
  },
  computed: {
    id() {
      return this.$route.params.id;
    }

  },
  watch: {},
  created() {
    this.getProjectDetail();
  },
  mounted() {},
  methods: {
    // 获取项目详情
    getProjectDetail() {
      const toast = this.$toast.loading({
        message: '加载中...',
        forbidClick: true,
        duration: 0
      });
      this.http.$post(this.$api.getProDetail, {
        id: this.id
      }).then(res => {
        this.info = res.data.info;
      }).finally(() => {
        toast.clear();
      });
    },
    previewImage(files, fileIndex) {
      this.$imagePreview({
        images: files.map(item => {
          return item.file_url;
        }),
        startPosition: fileIndex
      });
    },
    clickConfirm() {
      this.$router.push({ name: 'flightCheck', params: { id: this.id }});
    }
  }

};
</script>
<style lang="scss">
  #proList{
    .van-tabs--line .van-tabs__wrap{
      height: 100px;
    }
  }

</style>
<style lang="scss" scoped>
#proDetail{
  padding-bottom: 100px;
  .topWrap{
    height: 120px;
    line-height: 120px;
    padding: 0 32px;
    color: #fff;
    background: linear-gradient(90deg, $color-primary 0%, $color-primary-light 100%);
    display: flex;
    justify-content: space-between;
    h3{
      padding-left: 96px;
      background: url("../assets/images/proDetail_title_icon.png") no-repeat left center;
      background-size: 80px;
      width: 470px;
    }
  }
  section{
    padding: 48px 32px;
    background: #fff;
    margin-bottom: 16px;
    .title{
      padding-left: 48px;
      background: url("../assets/images/proDetail_title_icon2.png") no-repeat left center;
      background-size: 32px;
      margin-bottom: 48px;
    }
    .infoList{

      >li{
        display: flex;
        justify-content: space-between;
        line-height: 42px;
        margin-bottom: 26px;
        &:last-of-type{
          margin-bottom: 0;
        }
        label{
          color: $color-grey-light;
          font-size: 24px;
        }
        h5{
          width: 404px;
          text-align: right;
          font-size: 24px;
        }
        .rateList{
          li{
            line-height: 36px;
            margin-bottom: 8px;
            font-size: 24px;
            display: flex;
            span{
              width: 144px;
            }
            i{
              width: 24px;
              height: 24px;
              margin-left: 24px;
              display: inline-block;
              background: url("../assets/images/star.png") no-repeat center center;
              background-size: 24px;
            }
            .active{
              background-image: url("../assets/images/star_active.png");
            }
          }
        }
      }
    }
    .fileList{
      display: flex;
      flex-wrap: wrap;
      padding: 48px 0 0;
      li:not(:nth-of-type(3n)){
        margin: 0 30px 30px 0;
      }

      .file{
        width: 208px;
        height: 208px;
      }
    }
  }
}
</style>
